@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); /*Inter font*/

/* Write all the styles here  */

@font-face {
  /*Moon-walk font*/
  font-family: Moon-walk;
  src: url(./assets/fonts/Moon\ Walk.ttf);
}
body {
  background-color: #000720;
  overflow-x: hidden;
  user-select: none;
}
#sub-heading {
  color: #9a8dec;
  font-size: 35px;
  font-weight: 500;
  display: block;
  line-height: 50px;
}
#heading {
  color: #ffffff;
  font-family: Moon-walk;
  font-size: 65px;
  display: block;
  line-height: 65px;
}
#navbar {
  font-family: "Inter", sans-serif; /*Inter font*/
}
#navbar-cta {
  position: relative;
  top: 5px;
}
.nav-links {
  color: #e8dcdc;
  text-decoration: none;
}
#rectangle {
  display: flex;
  position: relative;
  top: -50px;
  justify-content: end;
  align-items: end;
  z-index: -150;
}
#rectangle2 {
  position: relative;
  top: -10px;
}
#rectangle3 {
  position: relative;
  z-index: -150;
}
#section1 {
  margin: 160px 7vw 0;
  position: relative;
  top: -220px;
  text-align: center;
  font-family: "Inter", sans-serif;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: end;
}
.section1items {
  width: 540px;
}
#section2 {
  margin: 0 7vw 0;
  display: flex;
  flex-wrap: wrap-reverse;
  font-family: "Inter", sans-serif;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: -300px;
}
@media (max-width: 1260px) {
  #section2 {
    margin-top: 0;
  }
}
.section2items {
  width: 540px;
  margin: 30px 0 30px;
}
#section3 {
  background: #9a8dec;
  font-family: "Inter", sans-serif;
  margin: 0px 0 30px;
  padding: 40px;
  text-align: center;
}
#section3itemscontainer {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 20px;
}
#section4 {
  margin: 0 7vw 0;
  text-align: center;
  color: #c3c1ce;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#section4text {
  height: 200px;
  max-width: 590px;
}
#section4imgcontainer {
  margin: 60px 0 60px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
}
#badge1 {
  position: relative;
  top: 29px;
}
#section5 {
  font-family: "Inter", sans-serif;
  margin: 0px 7vw 0px;
  text-align: center;
}
#section5item1 {
  padding: 30px 25px 15px;
  min-height: 60px;
  background: #362d6d;
  border-radius: 20px 20px 0 0;
  text-align: left;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
#section5item1 .text {
  word-wrap: break-word;
}
@media (max-width: 400px) {
  .custom-mx {
      margin-left: 3vw;
      margin-right: 3vw;
  }
  .nav-reg{
    margin-right: -40px;
  }
}
.img-hover-desc:hover{
  opacity: 0.7;
}
#dropdown-menu {
  font-size: 14px;
  background: #362d6d;
  border: 1px solid #dad8ea;
  border-radius: 5px;
  padding: 4px 12px 4px;
  color: #dad8ea;
}
#section5item2 {
  min-height: 360px;
  background: #1a1635;
  border-radius: 0 0 20px 20px;
  color: #c8cddc;
  text-align: center;
  display: flex;
  align-items: center;
}
#section5foldercontainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#section5folders {
  width: 185px;
  padding: 20px;
}
#section5folders img {
  margin: auto;
}
#section5folders figcaption {
  margin-top: 16px;
  font-size: 16px;
  line-height: 20.8px;
}
#section6 {
  font-family: "Inter", sans-serif;
  margin: 0px 7vw 60px;
}
.scrollToTop {
  position: fixed;
  bottom: 40px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #1a1635;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
  font-size: 30px;
}
#footer {
  min-height: 108px;
  background: #9a8dec;
  padding: 2vw 10vw 2vw;
}
#footer-left {
  max-width: 587px;
}
#footer-text {
  max-width: 527px;
  color: #c8cddc;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  size: 16px;
  line-height: 21.6px;
}
#footer-right {
  font-family: "Inter", sans-serif;
  line-height: 21.6px;
  size: 16px;
  font-weight: 700;
}

#signin {
  padding: 180px 16px 20px 16px;
  margin: 0 0 8vw;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #c3c1ce;
}
#signintext {
  width: 500px;
}
#signintextcontainer {
  text-align: left;
}
#signintextheading {
  margin-bottom: 52px;
}
#signininput {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;
}
#signininput div input {
  width: 100%;
  background: #1a1635;
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
  color: #ffffff;
}
#signinlogo {
  height: 417px;
  width: 462px;
}
#help {
  display: none;
}
#forgotpassworddiv:hover + #help {
  display: block;
}
#register {
  padding: 180px 16px 20px 16px;
  margin: 0vw 0vw 0vw;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #c3c1ce;
}
#register-inputs-div {
  width: 242px;
}
#register-inputs-div select {
  width: 100%;
  padding: 12px 16px 12px 16px;
  height: 42px;
  background: #1a1635;
  border-radius: 4px;
}
#registerlogo {
  position: sticky;
  top: 180px;
  height: 427.41px;
  width: 409.52px;
}
@media(max-width:400px){
  #signininput{
    transform: scale(1.1);
    margin-top: 80px;
  }
}
@media(max-width:350px){
  #signininput{
    transform: scale(1);
    margin-top: 40px;
  }
}
#profile {
  margin: auto;
  padding-top: 130px;
  padding-bottom: 50px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #c3c1ce;
  justify-content: center;
}
#domaincards {
  width: 259px;
}
@media screen and (max-width: 2560px) and (min-width: 1440px) {
  #register,
  #signin {
    gap: 6vw;
  }
  #profile {
    gap: 50px;
  }
  #suggestion-box {
    width: 351px;
  }
}
@media screen and (max-width: 1440px) and (min-width: 1024px) {
  #register,
  #signin {
    gap: 8vw;
  }
  #profile {
    padding-left: 16px;
    padding-right: 16px;
    gap: 50px;
  }
  #suggestion-box {
    width: 351px;
  }
}

@media screen and (max-width: 1024px) {
  #rectangle {
    top: -80px;
  }
  #section2 {
    margin-top: 50px;
  }
  #suggestion-box {
    width: 351px;
  }
}
@media screen and (min-width: 769px) {
  #section4imgcontainer div img {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
  }
  #section4imgcontainer div:hover img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}
@media screen and (max-width: 1024px) {
  #signinlogo,
  #registerlogo,
  #profilelogo {
    display: none;
  }
}
@media screen and (max-width: 769px) {
  .nav-links {
    margin-top: 20px;
  }
  #rectangle3 {
    left: -100%;
  }
  #signinlogo,
  #registerlogo {
    display: none;
  }
  #signintextcontainer {
    text-align: center;
  }
  #profileheading {
    text-align: center;
    padding: 0px 16px 10px 16px;
  }
  #profiletext {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #userdetails {
    width: 451px;
  }
  #suggestion-box {
    width: 451px;
  }
}
@media screen and (max-width: 640px) {
  #heading {
    font-size: 50px;
    line-height: 45px;
  }
  #sub-heading {
    font-size: 25px;
    line-height: 35px;
  }
  #section4imgcontainer div {
    width: 40%;
  }
  #badge1 {
    top: 25px;
  }
}
@media screen and (max-width: 480px) {
  #rectangle {
    width: 70%;
    margin-left: auto;
    top: -50px;
  }
  #section2 {
    margin-top: -60px;
  }
  #section5folders {
    width: 130px;
  }
  #section5folders img {
    width: 70px;
  }
  #section5folders figcaption {
    margin-top: 12px;
    font-size: 12px;
    line-height: 15.6px;
  }
  #section5item1 {
    font-size: 16px;
  }
  #dropdown-menu {
    padding: 5px 4px 5px;
  }
  #footer {
    padding: 6vw 5vw 12vw;
  }
  #signintext {
    width: 350px;
  }
  #signintextheading {
    margin-bottom: 24px;
  }
  #signininput {
    width: 100%;
  }
  .sub-heading {
    font-size: 18px;
  }
  .heading {
    font-size: 42px;
  }
  #register-inputs-div {
    width: 167px;
  }
  #register-inputs-div select {
    height: auto;
    width: 100%;
  }
  .full-length {
    min-width: 350px;
  }
  .full-lengthitem1 {
    margin-bottom: 16px;
  }
  #domaincards {
    width: 351px;
  }
  #userdetails {
    width: 351px;
  }
  #suggestion-box {
    width: 351px;
  }
}
@media screen and (max-width: 420px) {
  #rectangle {
    width: 75%;
  }
  #section2 {
    margin-top: -120px;
  }
  #badge1 {
    top: 17px;
  }
  #register-inputs-div {
    width: 150px;
  }
  #register-inputs-div select {
    width: 100%;
  }
  .registertext {
    max-width: 320px;
  }
  .full-length {
    min-width: 320px;
  }
  .full-lengthitem1 {
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 375px) {
  #rectangle {
    width: 80%;
  }
  .registertext {
    max-width: 300px;
  }
  .full-length {
    min-width: 300px;
  }
  .full-lengthitem1 {
    margin-bottom: 16px;
  }
  #register-inputs-div {
    width: 140px;
  }
  #domaincards {
    width: 290px;
  }
  #userdetails {
    width: 290px;
  }
  #suggestion-box {
    width: 290px;
  }
}
@media screen and (max-width: 320px) {
  #section5folders {
    width: 125px;
  }
  #section5folders img {
    width: 65px;
  }
  .registertext {
    max-width: 280px;
  }
  .full-length {
    min-width: 280px;
  }
  .full-lengthitem1 {
    margin-bottom: 16px;
  }
  #register-inputs-div {
    width: 130px;
  }
  #domaincards {
    width: 270px;
  }
  #userdetails {
    width: 270px;
  }
  #suggestion-box {
    width: 270px;
  }
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgba(255, 255, 255, 0.8); */
  z-index: 9999;
}

#loadimg {
  width: 100px;
  height: 100px;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content {
  transition: filter 0.3s ease;
}

.blurred {
  filter: blur(5px);
}
